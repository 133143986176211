export const cursos = [
    {id: 1, nome: 'Administração'},
    {id: 2, nome: 'Análises Clínicas'},
    {id: 3, nome: 'Design de Cílios'},
    {id: 4, nome: 'Design de Sobrancelhas'},
    {id: 5, nome: 'Enfermagem'},
    {id: 6, nome: 'Especialização Técnica'},
    {id: 7, nome: 'Estágio Enfermagem'},
    {id: 8, nome: 'Estágio Outros Cursos'},
    {id: 9, nome: 'Estética'},
    {id: 10, nome: 'Farmácia'},
    {id: 11, nome: 'Massoterapia'},
    {id: 12, nome: 'Nutrição'},
    {id: 13, nome: 'Podologia'},
    {id: 14, nome: 'Prótese Dentaria'},
    {id: 15, nome: 'Radiologia'},
    {id: 16, nome: 'Saúde Bucal'},
    {id: 17, nome: 'Segurança do Trabalho'},
    {id: 18, nome: 'Unha em Gel'},
    {id: 19, nome: 'Veterinária'},
  ];

