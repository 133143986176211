import React from "react";
import { Avatar, Box, Card, CardContent, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Stack, Typography } from "@mui/material";
import FmdGoodIcon from '@mui/icons-material/FmdGood';

import School from '../assets/School.svg'
import { unidades } from "../constants/unidades";
import useForm from "../providers/useForm";

function TelaUnidades() {
  const setUnidade = useForm((state) => state.setUnidade)

  return (
    <Box>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{px: 3}}
      >
        <Box sx={{ textAlign: "center", px: 2 }}>
          <img
            alt="Under development"
            src={School}
            style={{
              marginTop: 50,
              display: "inline-block",
              maxWidth: "100%",
              width: 450,
            }}
          />
        </Box>
        <Box sx={{maxWidth: 450}}>
        <Typography
          align="center"
          color="inherit"
          sx={{
            color: "#D14343",
            fontSize: "24px"
          }}
          variant="h1"
        >
          Qual unidade você estuda?
        </Typography>
        </Box>
        <Card sx={{width: { xs: "100%", md: "70%", lg: "60%"}}}>
        <CardContent>
        <List sx={{width: "100%"}}>
          {unidades.map((unidade, i) => (
            <React.Fragment key={unidade.id}>
            <ListItem
              onClick={() => setUnidade(unidade.nome)}
              secondaryAction={
                <IconButton edge="end" aria-label="delete">
                  <FmdGoodIcon color='error' />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton>
                <ListItemAvatar>
                  <Avatar sx={{ width: 24, height: 24, background: '#D14343', color: '#ffffff', fontSize: '0.75rem' }}>
                    {unidade.id}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText id={unidade.id} primary={unidade.nome} />
              </ListItemButton>
            </ListItem>
           {unidades.length - 1  !== i && <Divider sx={{borderColor: "#e7e7e7"}}/>}
           </React.Fragment>
          ))}
        </List>
        </CardContent>
        </Card>
      </Stack>
    </Box>
  );
}

export default TelaUnidades;
