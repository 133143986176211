import { useState } from "react";
import { Box, Button, Card, CardContent, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import LibraryAddCheckIcon from '@mui/icons-material/LibraryAddCheck';

import Status from '../assets/Status.svg'
import { cursos } from "../constants/cursos";
import useForm from "../providers/useForm";

function TelaReclamacao() {
  const [valueCurso, setValueCurso] = useState('');
  const [valueReclamacao, setValueReclamacao] = useState('');
  const showSelectCursos = useForm((state) => state.showSelectCursos)
  const setObrigado = useForm((state) => state.setObrigado)

  return (
    <Box>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{px: 3}}
      >
        <Box sx={{ textAlign: "center", px: 2 }}>
          <img
            alt="Under development"
            src={Status}
            style={{
              marginTop: 20,
              display: "inline-block",
              maxWidth: "100%",
              width: 450,
            }}
          />
        </Box>
        <Box sx={{maxWidth: 450}}>
        <Typography
          align="center"
          color="inherit"
          sx={{
            color: "#D14343",
            fontSize: "24px"
          }}
          variant="h1"
        >
          Descreva sua reclamação:
        </Typography>
        </Box>
        <Card sx={{width: { xs: "100%", md: "70%", lg: "60%"}}}>
          <CardContent>

            {showSelectCursos && 
          <FormControl sx={{ mb: 2 }} color="btn" fullWidth >
            <InputLabel htmlFor="grouped-select">Selecione seu curso</InputLabel>
            <Select onChange={(event) => setValueCurso(event.target.value)} defaultValue="" id="grouped-select" label="Selecione seu curso">
              <MenuItem value="">
                <em>Selecione uma opção</em>
              </MenuItem>
              {cursos.map(curso => (
                <MenuItem key={curso.id} value={curso.nome}>{curso.nome}</MenuItem>
              ))}
            </Select>
          </FormControl>}

          <TextField
          onChange={(event) => setValueReclamacao(event.target.value)}
          color="btn"
          margin="dense"
          fullWidth
          InputLabelProps={{
            shrink: true,
          }}
          id="outlined-multiline-flexible"
          label="Reclamação"
          placeholder="Descreva aqui 🙂"
          multiline
          rows={4}
        />
        <Button disabled={!Boolean(valueReclamacao)} onClick={() => setObrigado(valueReclamacao, valueCurso)} fullWidth variant="contained" color="success" sx={{mt: 2}} endIcon={<LibraryAddCheckIcon />}> ENVIAR </Button>
          </CardContent>
        </Card>
      </Stack>
    </Box>
  );
}

export default TelaReclamacao;
