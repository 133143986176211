import {  Avatar, Box, Card, CardContent, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, Stack, Typography } from "@mui/material";
import ExitToAppIcon from '@mui/icons-material/ExitToApp';

import Competitive from '../assets/Competitive.svg'
import { setores } from "../constants/setores";
import React from "react";
import useForm from "../providers/useForm";

function TelaSetor() {
  const setSetor = useForm((state) => state.setSetor)

  return (
    <Box>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{px: 3}}
      >
        <Box sx={{ textAlign: "center", px: 2 }}>
          <img
            alt="Under development"
            src={Competitive}
            style={{
              marginTop: 20,
              display: "inline-block",
              maxWidth: "100%",
              width: 450,
            }}
          />
        </Box>
        <Box sx={{maxWidth: 450}}>
        <Typography
          align="center"
          color="inherit"
          sx={{
            color: "#D14343",
            fontSize: "24px"
          }}
          variant="h1"
        >
          Deseja falar com o diretor referente a qual setor?
        </Typography>
        </Box>
        <Card sx={{width: { xs: "100%", md: "70%", lg: "60%"}}}>
        <CardContent>
        <List sx={{width: "100%"}}>
          {setores.map((setor, i) => (
            <React.Fragment key={setor.id}>
            <ListItem
              onClick={() => setSetor(setor.nome, setor.showSelectCursos)}
              secondaryAction={
                <IconButton edge="end" aria-label="delete">
                  <ExitToAppIcon color='error' />
                </IconButton>
              }
              disablePadding
            >
              <ListItemButton>
                <ListItemAvatar>
                  <Avatar sx={{ width: 24, height: 24, background: '#D14343', color: '#ffffff', fontSize: '0.75rem' }}>
                    {setor.id}
                  </Avatar>
                </ListItemAvatar>
                <ListItemText id={setor.id} primary={setor.nome} />
              </ListItemButton>
            </ListItem>
            {setores.length - 1  !== i && <Divider sx={{borderColor: "#e7e7e7"}}/>}
            </React.Fragment>
          ))}
        </List>
        </CardContent>
        </Card>
      </Stack>
    </Box>
  );
}

export default TelaSetor;
