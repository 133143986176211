import axios from 'axios'
import create from 'zustand'
import { config } from '../config'

const useForm = create((set, get) => ({
  view: "TelaInicio",
  unidade: null,
  setor: null,
  curso: null,
  reclamacao: null,
  showSelectCursos: false,
  nome: null,
  whatsapp: null,
  isLoading: false,
  setUnidade: (unidade) => set({ unidade, view: "TelaSetor"  }),
  setSetor: (setor, showSelectCursos) => set({ setor, showSelectCursos, view: "TelaReclamacao"  }),
  setObrigado: (reclamacao, curso) => set({ reclamacao, curso, view: "TelaObrigado" }),
  setView: (view) => set({ view }),
  sendForm: async (nome, whatsapp) => {
     var data = {
      unidade: get().unidade,
      setor: get().setor,
      curso: get().showSelectCursos === false ? null : get().curso,
      reclamacao: get().reclamacao,
      nome,
      whatsapp
    }
    set({ isLoading: true })
    
    try {
      await axios({
        method: 'POST',
        url: `${config.URL}/form`,
        headers: { 'Content-Type': 'application/json' },
        data
      })
      set({ isLoading: false })
    } catch (error) {
      set({ isLoading: false })
      console.log(error)
    }
  },
}))

export default useForm