import { Alert, AlertTitle, Box, Button, Card, CardContent, Stack, TextField, Typography, useMediaQuery } from "@mui/material";
import Appreciation from '../assets/Appreciation.svg'
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import NotInterestedIcon from '@mui/icons-material/NotInterested';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { useState } from "react";
import useForm from "../providers/useForm";
import NumberFormat from "react-number-format";
import { toast, ToastContainer } from "react-toastify";
import Lottie from "lottie-react";
import Loading from '../assets/Loading.json'
import 'react-toastify/dist/ReactToastify.css';

function TelaObrigado() {
  const matches = useMediaQuery('(max-width:600px)');
  const [valueWhatsapp, setValueWhatsapp] = useState('');
  const [valueNome, setValueNome] = useState('');
  const [receberResposta, setReceberResposta] = useState(true);
  const isLoading = useForm((state) => state.isLoading)
  const setView = useForm((state) => state.setView)
  const sendForm = useForm((state) => state.sendForm)

  const handleForm = (option) => {
    var formattingValueWhatsapp = valueWhatsapp === "" ? null : valueWhatsapp
    var formattingValueNome = valueNome === "" ? null : valueNome
    
    if(option && !formattingValueWhatsapp){
        toast.warning(`Insira o seu número de WhatsApp.`)
    } else {
      setReceberResposta(false)
      sendForm(formattingValueNome, formattingValueWhatsapp)
    }

  }

  return (
    <Box>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{px: 3}}
      >
       {isLoading && <Box sx={{ textAlign: "center", px: 4 }}>
        <Lottie style={{height: 350}} animationData={Loading} />
        </Box>} 

        {!isLoading && <Box sx={{ textAlign: "center", px: 2 }}>
          <img
            alt="Under development"
            src={Appreciation}
            style={{
              display: "inline-block",
              maxWidth: "100%",
              width: 450,
            }}
          />
        </Box> }
        {!isLoading && !receberResposta &&  <Box >
        <Typography
          align="center"
          color="inherit"
          sx={{
            color: "#D14343",
            fontSize: "24px"
          }}
          variant="h1"
        >
          Obrigado por enviar{matches ? <br /> : " "}mensagem para Direção
        </Typography>
        </Box>}
        {receberResposta &&  <Box>
        <Alert severity="warning">
        <AlertTitle>Ainda não acabou.</AlertTitle>
         Para finalizar a sua reclamação,{matches ? <br /> : " "}escolha uma das opções abaixo:
        </Alert>
        </Box> }
        {receberResposta && <Card sx={{width: { xs: "100%", md: "70%", lg: "60%"}}}>
          <CardContent>
            <Box>
              <Typography sx={{p: 1, mb: 1}}>
              Caso queira receber uma resposta à sua reclamação, preencha os dados abaixo.
              </Typography>
            </Box>
          <TextField
          onChange={(event) => setValueNome(event.target.value)}
          margin="dense"
          color="btn"
          fullWidth
          id="nome"
          label="Seu nome (opcional)"
        />
        <NumberFormat
          customInput={TextField}
          format="(##) #####-####"
          mask="_"
          fullWidth
          placeholder="(71) 98848-0013"
          onChange={(event) => setValueWhatsapp(event.target.value)}
          margin="dense"
          color="btn"
          id="whatsapp"
          label="Seu WhatsApp"
          required={true}
          type="tel"
          />
        <Button onClick={() => handleForm(true)} fullWidth variant="contained" color="success" endIcon={<ThumbUpIcon />} sx={{mt: 1}} > RECEBER </Button>
        <Button onClick={() => handleForm(false)} fullWidth variant="outlined" color="error" endIcon={<NotInterestedIcon />} sx={{mt: 1}} > NÃO RECEBER </Button>
          </CardContent>
        </Card>}
        {!isLoading && !receberResposta && <Button onClick={() => setView('TelaInicio')} fullWidth variant="contained" color="success" endIcon={<RestartAltIcon />} sx={{mt: 1}} > NOVA RECLAMAÇÃO </Button> }
      </Stack>
      <ToastContainer position="bottom-center" theme="colored"/>
    </Box>
  );
}

export default TelaObrigado;
