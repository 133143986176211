import { Box, Button, Card, CardContent, Stack, Typography } from "@mui/material";
import Inicial from '../assets/Inicial.json'
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite';

import Lottie from "lottie-react";
import useForm from "../providers/useForm";

function TelaInicio() {
  const setView = useForm((state) => state.setView)

  return (
    <Box>
      <Stack
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={2}
        sx={{px: 3}}
      >
        <Box sx={{ textAlign: "center", px: 4 }}>
        <Lottie style={{height: 250}} animationData={Inicial} />
        </Box>
        <Box >
        <Typography
          align="center"
          color="inherit"
          sx={{
            color: "#D14343"
          }}
          variant="h3"
        >
          Seja bem-vindo!
        </Typography>
        </Box>
        <Card sx={{width: { xs: "100%", md: "70%", lg: "60%"}}}>
          <CardContent>
            <Box>
              <Typography sx={{p: 1, mb: 1}}>
              Fique à vontade para deixar suas reclamações aqui e falar com o diretor. Estamos à sua disposição para melhor atendê-lo.
              </Typography>
            </Box>
        <Button onClick={() => setView('TelaUnidades')} fullWidth variant="contained" color="success" endIcon={<PlayCircleFilledWhiteIcon />} sx={{mt: 1}} > INICIAR </Button>
          </CardContent>
        </Card>
      </Stack>
    </Box>
  );
}

export default TelaInicio;
