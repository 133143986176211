import { Box } from "@mui/material";
import { TabContext, TabPanel} from '@mui/lab';
import { NavBar } from "./components/NavBar";
import { tabs } from "./constants/tabs";
import { Footer } from "./components/Footer";
import useForm from "./providers/useForm";

function App() {
  const view = useForm((state) => state.view)

  return (
    <Box sx={{ width: "100%", height: "100vh" }} >
      <NavBar />
      <TabContext value={view}>
       {tabs.map(tab => (<TabPanel key={tab.nome} sx={{p: 0}} value={tab.nome}>{tab.children}</TabPanel>))} 
      </TabContext>
      <Footer />
    </Box>
  );
}

export default App;
