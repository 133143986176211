import { AppBar, Box, Toolbar} from "@mui/material";
import LogoProcursos from '../assets/logoprojeta.svg'

export const NavBar = () => {
  return (
    <AppBar
        position="static"
        sx={{
          background: "#fff",
          textAlign: "center",
          alignItems: "center"
        }}
      >
        <Toolbar>
        <Box sx={{ textAlign: "center" }}>
          <img
            alt="Under development"
            src={LogoProcursos}
            style={{
              display: "inline-block",
              width: 130,
            }}
          />
        </Box>
        </Toolbar>
      </AppBar>
  );
}
