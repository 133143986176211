import { Box, Typography} from "@mui/material";

export const Footer = () => {
  return (
    <Box sx={{ mt: 4, pb: 1, textAlign: 'center' }}>
        <Typography variant="body2" color="textSecondary">
          © 2022 Rede ProCursos – Todos os Direitos Reservados
        </Typography>
      </Box>
  );
}
