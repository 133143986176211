import TelaInicio from "../views/TelaInicio";
import TelaUnidades from "../views/TelaUnidades";
import TelaSetor from "../views/TelaSetor";
import TelaReclamacao from "../views/TelaReclamacao";
import TelaObrigado from "../views/TelaObrigado";

export const tabs = [
    {nome: "TelaInicio", children: <TelaInicio />},
    {nome: "TelaUnidades", children: <TelaUnidades />},
    {nome: "TelaSetor", children: <TelaSetor />},
    {nome: "TelaReclamacao", children: <TelaReclamacao />},
    {nome: "TelaObrigado", children: <TelaObrigado />},
  ]